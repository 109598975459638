define(['cookie', 'columboService', 'columboConstants', '$location', 'siteObj', 'uaParser', '$window', '$navigator'], function(cookie, columboService, columboConstants, $location, siteObj, uaParser, $window, $navigator) {
  const Metrics = function(ua) {
    const module = {};
    const sessionId = cookie.get(columboConstants.chaseSessionKey);
    const userId = cookie.get(columboConstants.chaseUserKey);
    const _uaParser = new uaParser();
    const uaParserResult = ua || _uaParser.getResult();

    // https://github.com/faisalman/ua-parser-js/issues/182
    // https://github.com/faisalman/ua-parser-js/issues/224
    const isPC = function(operatingSystem) {
      const hashTable = {
        'aix': true,
        'amiga os': true,
        'android': false,
        'arch': true,
        'bada': false,
        'beos': true,
        'blackberry': false,
        'centos': true,
        'chromium os': true,
        'contiki': true,
        'fedora': true,
        'firefox os': false,
        'freebsd': true,
        'debian': true,
        'dragonfly': true,
        'gentoo': true,
        'gnu': true,
        'haiku': true,
        'hurd': true,
        'ios': false,
        'joli': true,
        'linpus': true,
        'mac os': true,
        'mageia': true,
        'mandriva': true,
        'meego': false,
        'minix': true,
        'mint': true,
        'morph os': true,
        'netbsd': true,
        'nintendo': false,
        'openbsd': true,
        'openvms': true,
        'os/2': true,
        'palm': false,
        'pc-bsd': true,
        'pclinuxos': true,
        'playstation': false,
        'qnx': true,
        'redhat': true,
        'rim tablet os': false,
        'risc os': true,
        'sailfish': false,
        'series40': false,
        'slackware': true,
        'solaris': true,
        'suse': true,
        'symbian': false,
        'tizen': true,
        'ubuntu': true,
        'unix': true,
        'vectorlinux': true,
        'webos': false,
        'windows': true,
        'windows phone': false,
        'windows mobile': false,
        'zenwalk': true
      };
      return (operatingSystem && hashTable[operatingSystem.toLowerCase()]) ? hashTable[operatingSystem.toLowerCase()] : false;
    };

    const _isPopularMobileTabletOs = function(mobileOs) {
      const hashTable = {
        'android': true,
        'ios': true,
        'ipados': true
      };
      return (mobileOs && hashTable[mobileOs.toLowerCase()])
        ? hashTable[mobileOs.toLowerCase()] : false;
    };

    const _get = (obj, path, defaultValue) => {
      const result = String.prototype.split.call(path, /[,[\].]+?/)
        .reduce((res, key) => (res !== null && res !== undefined) ? res[key] : res, obj);
      return (result === undefined || result === obj) ? defaultValue : result;
    };

    // sections to include in the metrics data
    // optionally pass in an element if collecting element-specific metrics
    const _getMetrics = function(sections, args = null) {
      const eventData = {};
      if (args) {
        eventData.args = args;
      }
      sections.forEach((section) => {
        let element;
        switch (section) {
          case 'ux':
            eventData.server = {
              ip: '',
              hostname: '',
              elysium_version: module.getSiteObjItem('version'),
              columbo_version: columboConstants.columboVersion
            };

            eventData.request = {
              url: $location.toString(),
              client_timestamp: Math.round(new Date().getTime() / 1000)
            };

            eventData.property = {
              site_id: module.getSiteObjItem('siteID'),
              channel: module.getSiteObjItem('siteCode'),
              shipping_country_code: module.getSiteObjItem('shippingCountry'),
              subsite: module.getSiteObjItem('subsiteCode'),
              is_mobile_version: module.getSiteObjItem('siteIsMobile'),
              locale: module.getSiteObjItem('siteDefaultLocale')
            };

            eventData.device = {
              cookie: userId,
              sess_cookie: sessionId,
              type: {
                family: uaParserResult.device.type,
                brand: uaParserResult.device.vendor,
                model: uaParserResult.device.model,
                is_mobile: uaParserResult.device.type === 'mobile',
                is_tablet: uaParserResult.device.type === 'tablet',
                is_pc: uaParserResult.device.type !== 'mobile' && uaParserResult.device.type !== 'tablet' ?
                  isPC(uaParserResult.os.name) : ''
              },
              screen: {
                width: $window.screen.width,
                height: $window.screen.height,
                devicePixelRatio: ($window.devicePixelRatio) ? $window.devicePixelRatio : ''
              },
              browser: {
                family: uaParserResult.browser.name,
                version: uaParserResult.browser.version,
                major_version: uaParserResult.browser.major
              },
              ip: {
                country: module.getSiteObjItem('customerLocation')
              }
            };

            eventData.experiments = module.getSiteObjItem('experiments');
            eventData.errors = [];
            eventData.event = args;
            eventData.attributes = [];
            eventData.nonce = module.getSiteObjItem('nonce');
            break;
          case 'meta':
            eventData.meta = module.getMetaData();
            break;
          case 'reporting':
            eventData.reporting = module.getReportingData();
            break;
          case 'performance':
            eventData.performance = module.getPerformanceStats();
            break;
          case 'experiments':
            eventData.experiments = module.getExperiments();
            break;
          case 'widget':
            if (args[0] && args[0].nodeType === 1) {
              element = args[0];
            }
            if (args[0] && args[0].srcElement) {
              element = args[0].srcElement;
            }
            eventData.widget = module.getWidgetData(element);
            break;
          case 'products':
            eventData.products = module.getProducts();
            break;
          case 'basket':
            eventData.basket = module.getBasket(args);
            break;
          case 'web-vitals':
            eventData.webVitals = module.webVitals;
            break;
          default:
            eventData[section] = {
              message: 'this metric is not defined.'
            };
        }
      });
      return eventData;
    };

    const _findWidgetElement = function(element) {
      let elementToSearch = element;
      while (elementToSearch !== document.body && elementToSearch.parentNode) {
        if (elementToSearch.getAttribute('data-widget-id')) {
          return elementToSearch;
        }
        elementToSearch = elementToSearch.parentNode;
      }
      return null;
    };

    const _getWidgetData = function(element) {
      const widget = {};
      if (element) {
        const widgetElement = _findWidgetElement(element);
        if (widgetElement) {
          widget.id = widgetElement.getAttribute('data-widget-id');
          widget.type = widgetElement.getAttribute('data-block-type');
          widget.name = widgetElement.getAttribute('data-block-name');
          widget.link = _findHref(widgetElement);
        }
      }
      return widget;
    };

    const _findHref = function(element) {
      if (element.href) {
        return element.href;
      }
      const link = element.querySelector('[href]');
      if (link && link.href) {
        return link.href;
      }
      return null;
    };

    const _getMetaData = function() {
      if (module && !module.meta) {
        module.meta = {
          sessionId: sessionId,
          userId: userId,
          URL: $location.toString(),
          siteDefaultLocale: module.getSiteObjItem('siteDefaultLocale'),
          countryCode: module.getSiteObjItem('countryCode'),
          currency: module.getSiteObjItem('currencyType'),
          customerLocale: module.getSiteObjItem('customerLocale'),
          customerLocation: module.getSiteObjItem('customerLocation'),
          nonce: module.getSiteObjItem('nonce'),
          shippingCountry: module.getSiteObjItem('shippingCountry'),
          elysiumVersion: module.getSiteObjItem('version'),
          serverIP: module.getSiteObjItem('serverIP'),
          siteCode: module.getSiteObjItem('siteCode'),
          subsiteCode: module.getSiteObjItem('subsiteCode'),
          siteID: module.getSiteObjItem('siteID'),
          productID: module.getSiteObjItem('productID')
        };
      }
      return module.meta;
    };

    const _getReportingData = function() {
      if (module && !module.reportingData) {
        module.reportingData = Object.assign({}, module.getPageTypeAndTheme(),
          module.getDeviceAndBrowserGroup(), module.getNetworkData());
      }

      return module.reportingData;
    };

    const _getSiteObjItem = function(key) {
      return _get(siteObj, key) ? siteObj[key] : '';
    };

    const _getExperiments = function() {
      if (!module.experiments) {
        module.experiments = _getSiteObjItem('experiments');
      }
      return module.experiments;
    };

    const _getPerformanceStats = function(forceCalculate) {
      // use forceCalculate to ignore stored results
      if (!module.performanceStats || forceCalculate) {
        let timing;
        let stats = {};

        // Check for timing support
        if (module.isTimingEnabled) {
          timing = module.timingAPI;
          const startTime = timing.navigationStart;
          // if this function is called by an onLoad handler, loadEventEnd will be 0 so use loadEventStart instead
          let endTime = timing.loadEventStart;
          if (timing.loadEventEnd > 0) {
            endTime = timing.loadEventEnd;
          }
          const totalTime = endTime - startTime;

          // Gather stats
          stats = {
            // Total load time from start until end
            navigationTime: totalTime,

            // Total network time from start to fetch
            redirectTime: timing.redirectEnd - timing.redirectStart,

            // Total time checking/retrieving from cache
            cacheTime: timing.domainLookupStart - timing.fetchStart,

            // Total DNS query time
            dnsTime: timing.domainLookupEnd - timing.domainLookupStart,

            // Total time spent connecting, SSL handshakes etc
            connectionTime: timing.connectEnd - timing.connectStart,

            // Total time from connection until last byte
            backendLoadTime: timing.responseEnd - timing.requestStart,

            // Total time from start to DOM interative
            domInteractiveTime: timing.domInteractive - startTime,

            // Total time receiving/parsing the DOM
            domParsingTime: timing.domInteractive - timing.domLoading,

            // Total time spend constructing the DOM tree
            domReadyTime: timing.domComplete - timing.domInteractive,

            // Total time constructing the DOM until load event fires
            frontendLoadTime: endTime - timing.domInteractive,
          };

          if (endTime !== timing.loadEventStart) {
            // Total time running the load event listeners
            stats.loadEventTime = endTime - timing.loadEventStart;
          }

          // IE
          if (typeof timing.msFirstPaint === 'number') {
            timing.firstPaint = timing.msFirstPaint;
          }

          // Add first paint to stats
          if (timing.firstPaint) {
            stats.firstPaintTime = Math.round(timing.firstPaint - startTime);
          }

          // Chrome
          if ($window.PerformancePaintTiming && performance.getEntriesByType('paint')[0]) {
            stats.firstPaintTime = Math.round(performance.getEntriesByType('paint')[0].startTime);
          }
        }

        module.performanceStats = stats;
      }
      return module.performanceStats;
    };

    const _addWebVitalsValue = function (metric) {
      if (!module.webVitals) module.webVitals = {};
      switch(metric.name) {
        case 'CLS':
          module.webVitals.cumulativeLayoutShift = metric.value;
          break;
        case 'FCP':
          module.webVitals['firstContentfulPaint'] = Math.trunc(metric.value);
          break;
        case 'FID':
          module.webVitals['firstInputDelay'] = Math.trunc(metric.value);
          break;
        case 'LCP':
          module.webVitals['largestContentfulPaint'] = Math.trunc(metric.value);
          break;
        case 'TTFB':
          module.webVitals['timeToFirstByte'] = Math.trunc(metric.value);
          break;
        default:
          module.webVitals[metric.name] = metric.value;
      }
    };

    const _getProducts = function() {
      if (!module.products) {
        const productsDomElements = Array.from(document.querySelectorAll('.thg-track[rel]'));
        module.products = productsDomElements.map((product) => {
          return {
            Pid: product.getAttribute('rel')
          };
        });
      }
      return module.products;
    };

    const _getBasket = function(args) {
      if (!module.basket) {
        const data = args[0];
        const basketItems = data.simpleBasket.basketItems;
        const basketItemsJson = basketItems.map((basketItem, index) => {
          return {
            BasketItemId: index + 1,
            ProductId: (!basketItem.productId) ? basketItem.productId : 0,
            ChildMasterProductId: (!basketItem.childMasterProductId) ? basketItem.childMasterProductId : 0,
            FreeGift: basketItem.freeGift,
            Quantity: basketItem.quantity,
            Price: basketItem.price.split(';')[1]
          };
        });

        module.basket = {
          BasketId: data.simpleBasket.basketId,
          lastAddedItem: data.simpleBasket.lastAddedItem.productId,
          TotalPrice: data.simpleBasket.totalprice.split(';')[1],
          BasketItems: basketItemsJson,
          RequestEventId: data.simpleBasket.requestEventId,
          Event: 'basketItemAdded'
        };
      }
      return module.basket;
    };

    module.isTimingEnabled = !!$window.performance;
    module.timingAPI = {};
    if (module.isTimingEnabled && !!$window.performance.timing) {
      module.timingAPI = $window.performance.timing;
    }

    const _getNetworkData = () => {
      module.network = {};
      if ($navigator.connection) {
        // Network type that browser uses
        let type = $navigator.connection.type ? $navigator.connection.type : null;

        // Effective connection type determined using a combination of recently
        // observed rtt and downlink values
        let effectiveNetworkType = $navigator.connection.effectiveType;

        module.network = {
          networkType: module.capitalise(type),
          effectiveNetworkType: module.replaceWhitespaceAndDash(effectiveNetworkType)
        };
      }

      return module.network;
    };

    const _getPageTypeAndTheme = () => {
      if (!(module && module.page)) {
        let pageType;
        if (siteObj.dept === 'home' && siteObj.homepage === true) {
          pageType = siteObj.dept;
        } else if (siteObj.section === 'search') {
          pageType = siteObj.section;
        } else if (siteObj.type === 'list' || siteObj.type === 'product') {
          pageType = siteObj.type;
        } else if (siteObj.page === 'basket' || siteObj.page === 'allReviews') {
          pageType = siteObj.page;
        } else {
          pageType = null;
        }

        let theme;
        if (pageType === 'product') {
          let themeArray = siteObj.productPageTheme.split(/(?=[A-Z])/);
          themeArray.splice(-2, 2);
          theme = themeArray.join('');
        } else if (pageType === 'basket') {
          theme = siteObj.basketPageTheme.split(/(?=[A-Z])/)[0];
        } else {
          theme = null;
        }

        module.page = {
          pageType: module.capitalise(pageType),
          pageTheme: module.capitalise(theme)
        }
      }

      return module.page;
    };

    const _getIPhoneModel = () => {
      let height = $window.screen.height;
      let width = $window.screen.width;
      let pixelRatio = $window.devicePixelRatio;

      // iPhone X
      if ((height / width === 812 / 375)  && (pixelRatio === 3))
      {
        return 'iPhone X';
      }
      // iPhone 6+/6s+/7+ and 8+
      else if (((height / width === 736 / 414)  && (pixelRatio === 3))
          || ((height / width === 667 / 375)  && (pixelRatio === 3)))
      {
        return 'iPhone 6/6s/7/8 Plus';
      }
      // iPhone 6/6s/7 and 8
      else if (((height / width === 667 / 375)  && (pixelRatio === 2))
          || ((height / width === 1.775)  && (pixelRatio === 2)))
      {
        return 'iPhone 6/6s/7/8';
      } else {
        return 'iPhone (other)';
      }
    }

    const _getDeviceAndBrowserGroup = () => {
      let os;
      let browser = uaParserResult.browser.name;
      let device = uaParserResult.device.model ? uaParserResult.device.model : null;
      let deviceGroup = uaParserResult.device.type === 'mobile' ?
        'Mobile' : uaParserResult.device.type === 'tablet' ?
          'Tablet' : isPC(uaParserResult.os.name) ?
            'Desktop' : 'Unknown';

      // Currently sending granular information when it is iPhone
      // or 'Moto G4'
      if (deviceGroup === 'Mobile') {
        if (device === 'iPhone') {
          device = module.getIPhoneModel();
        } else if (uaParserResult.ua.match(/Moto G \(4\)/i)) {
          device = 'Moto G4';
        } else {
          device = null;
        }
      }

      if (deviceGroup === 'Desktop') {
        os = module.getDesktopOsName();
      } else {
        let osName = uaParserResult.os.name;
        os = module.isPopularMobileTabletOs(osName) ? osName : null;
      }

      return module.deviceAndBrowserGroup = {
        browser: module.replaceWhitespaceAndDash(browser),
        device: module.replaceWhitespaceAndDash(device),
        deviceGroup: deviceGroup,
        operatingSystem: os
      };
    };

    const _getDesktopOsName = () => {
      let os = null;
      let platform = $window.navigator.platform;
      let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
      let windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Linux/.test(platform)) {
        os = 'Linux';
      }

      return os;
    }

    const _capitalise = (str) => {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : null;
    }

    const _replaceWhitespaceAndDash = (str) => {
      return str ? str.replace(/[\s+-]/g, '_') : null;
    }

    // Public API methods
    module.getExperiments = _getExperiments;
    module.get = _get;
    module.getPerformanceStats = _getPerformanceStats;
    module.getMetaData = _getMetaData;
    module.getReportingData = _getReportingData;
    module.getWidgetData = _getWidgetData;
    module.getProducts = _getProducts;
    module.getMetrics = _getMetrics;
    module.getBasket = _getBasket;
    module.getSiteObjItem = _getSiteObjItem;
    module.addWebVitalsValue = _addWebVitalsValue;
    module.getNetworkData = _getNetworkData;
    module.getPageTypeAndTheme = _getPageTypeAndTheme;
    module.getDeviceAndBrowserGroup = _getDeviceAndBrowserGroup;
    module.getIPhoneModel = _getIPhoneModel;
    module.getDesktopOsName = _getDesktopOsName;
    module.isPopularMobileTabletOs = _isPopularMobileTabletOs;
    module.capitalise = _capitalise;
    module.replaceWhitespaceAndDash = _replaceWhitespaceAndDash;

    return module;
  };

  return Metrics;
});

